export const tableCols = [{
  prop: 'title',
  label: '文章标题',
  width: 200
}, {
  prop: 'author',
  label: '作者与出处'
}, {
  prop: 'content',
  label: '文章',
  type: 'content',
  width: 80
}, {
  prop: 'references',
  label: '参考'
}, {
  prop: 'createUser',
  label: '创建人',
  width: 80
}, {
  prop: 'createTime',
  label: '创建时间',
  isSort: true
}, {
  prop: 'updateUser',
  label: '更新人',
  width: 80
}, {
  prop: 'updateTime',
  label: '更新时间',
  isSort: true
}]

export function handleCols (...funcs) {
  return [{
    size: 'mini',
    type: 'danger',
    label: '删除',
    click: funcs[0]
  }]
}
