<template>
  <div class="card">
  <p class="title"><i class="fa fa-th-large fa-lg"></i>科普文章</p>
  <i class='delete-icon el-icon-close' @click="handleClose"></i>
  <el-form :model="ruleForm2" status-icon :rules="rules" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
    <el-form-item label="文章标题" prop="title">
      <el-input clearable v-model="ruleForm2.title" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="作者与出处" prop="author">
      <el-input clearable v-model="ruleForm2.author" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="文章内容" prop="content">
      <Editor v-model="ruleForm2.content" />
    </el-form-item>
    <el-form-item label="文章参考" prop="references">
      <el-input clearable type='textarea' autosize v-model="ruleForm2.references" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm2')">确 定</el-button>
      <el-button @click="resetForm">重 置</el-button>
    </el-form-item>
  </el-form>
  </div>
</template>

<script>
import Editor from '../editor'
export default {
  name: 'Form',
  components: {
    Editor
  },
  props: {
    data: {
      default: {}
    },
    isModify: {
      default: false
    }
  },
  created () {
    if (!this.isModify) return
    const { author, content, references, title } = this.data
    this.ruleForm2 = {
      author,
      content,
      references,
      title,
      files: '',
      links: ''
    }
  },
  data () {
    return {
      ruleForm2: {
        author: '',
        content: '',
        references: '',
        title: '',
        files: '',
        links: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入文章标题', trigger: 'blur' }
        ],
        author: [
          { required: true, message: '请输入文章作者', trigger: 'blur' }
        ],
        content: [
          { required: true }
        ]
      }
    }
  },
  watch: {
    'ruleForm2.content': {
      immediate: true,
      handler (val) {
        // 保存图片的src
        const reg = /<img[^>]+src=['"]([^'"]+)['"]+/g
        const result = []
        let temp = []
        while ((temp = reg.exec(val)) != null) {
          result.push(temp[1].split('/').slice(-2).join('/'))
        }
        this.ruleForm2.files = result
        // 提取所有的a标签，保存href和text
        const _box = document.createElement('div')
        _box.innerHTML = val
        const allTag = _box.querySelectorAll('a')
        const temp2 = []
        for (const tag of allTag) {
          const href = tag.getAttribute('href')
          console.log(href)
          if (href.startsWith('http')) {
            temp2.push({
              href,
              text: tag.innerText
            })
          }
        }
        this.ruleForm2.links = JSON.stringify(temp2)
      }
    }
  },
  methods: {
    handleClose () {
      this.resetForm()
      this.$emit('onClose')
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return this.$message.error('添加失败')
        const obj = {}
        for (const key in this.ruleForm2) {
          obj[key] = this.ruleForm2[key]
        }
        this.isModify ? this.$emit('onModify', { ...this.data, ...obj }) : this.$emit('successAdd', obj)
      })
    },
    resetForm () {
      this.ruleForm2 = {
        author: '',
        content: '',
        references: '',
        title: ''
      }
    }
  }
}
</script>
