import axios, { reLogin } from '../axios'

/**
 * @param {object} params
 * @param {string} author
 * @param {string} content
 * @param {string} files
 * @param {string} references
 * @param {string} title
 */
async function popularCreate (params) {
  if (!params) return null
  try {
    const { data } = await axios.post('/popular/create', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {object} params
 * @param {number} pn
 * @param {number} ps
 * @param {string} keyword
 */
async function popularList (params) {
  if (!params) return null
  try {
    const { data } = await axios.get('/popular/list', { params })
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {number} id
 */
async function popularDelete (id) {
  if (!id) return null
  try {
    const { data } = await axios.delete(`/popular/${id}`)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * 改
 * @param {object} params
 * @param {string} author
 * @param {string} content
 * @param {string} files
 * @param {string} id
 * @param {string} references
 * @param {string} title
 */
async function popularPut (params) {
  if (!params) return null
  try {
    const { data } = await axios.put('/popular/put', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
export {
  popularCreate,
  popularList,
  popularDelete,
  popularPut
}
